// import logo from './logo.svg';
import * as React from "react";
import { useEffect, useState } from "react";
import {
  getAllIterations,
  setUpAuth,
  getIterationWorkItems,
  updateCompletedTime,
  getWorkItemsBatch,
} from "../services/base-service";
import { useParams } from "react-router-dom";
import { Depths } from "@fluentui/react";
import "./Tasks.css";
import { ChevronDownIcon, ChevronUpIcon } from "@fluentui/react-icons-mdl2";
import Stopwatch from "../components/Stopwatch.js";

function Tasks() {
  let [iterations, setIterations] = useState([]);
  let [workItems, setWorkItems] = useState([]);
  let [workItemDetails, setWorkItemDetails] = useState(new Map([]));
  let [opened, setOpened] = useState([]);
  let { name } = useParams();

  const fetchIterations = (val) => {
    getAllIterations(val).then((response) => {
      setIterations(response.data.value);
      setOpened(Array(response.data.value.length).fill(false));
    });
  };

  const closeIteration = (index) => {
    opened[index] = false;
    setOpened([...opened]);
  };

  const fetchWorkItems = (projectName, iterationId, index) => {
    getIterationWorkItems(projectName, iterationId).then((response) => {
      opened[index] = true;
      setOpened([...opened]);
      setWorkItems(response.data.workItemRelations);
      getWorkItemsBatch(
        response.data.workItemRelations.map((v) => v.target.id)
      ).then((response) => {
        setWorkItemDetails(new Map(response.data.value.map((v) => [v.id, v])));
      });
    });
  };

  const updateTime = (workItemId, time) => {
    updateCompletedTime(workItemId, time).then((response) => {
      alert(
        `Task time updated to: ${response.data.fields["Microsoft.VSTS.Scheduling.CompletedWork"]}`
      );
    });
  };

  useEffect(() => {
    setUpAuth();
    fetchIterations(name);
  }, [name]);

  return (
    <div className="App">
      <h1>Selecciona la iteración y tarea:</h1>
      <div className="iteration-grid">
        {iterations.length > 0 &&
          iterations.map((val, index) => (
            <div
              key={val.id}
              className="iteration-card"
              style={{ boxShadow: Depths.depth4 }}
            >
              <div
                className="iteration-header"
                onClick={() =>
                  opened[index]
                    ? closeIteration(index)
                    : fetchWorkItems(name, val.id, index)
                }
              >
                {!opened[index] && <ChevronDownIcon />}
                {opened[index] && <ChevronUpIcon />}
                <p>{val.name}</p>
              </div>
              {opened[index] &&
                workItems.length > 0 &&
                workItems.map((wi) => (
                  <ul key={wi.target.id}>
                    <li>
                      <p>
                        <span className="task-id">{wi.target.id}</span>
                        {workItemDetails.size > 0 &&
                          workItemDetails.get(wi.target.id) && (
                            <span>
                              {" "}
                              -{" "}
                              {
                                workItemDetails.get(wi.target.id).fields[
                                  "System.Title"
                                ]
                              }
                            </span>
                          )}
                      </p>
                      <Stopwatch
                        startTime={
                          workItemDetails.get(wi.target.id)?.fields[
                            "Microsoft.VSTS.Scheduling.CompletedWork"
                          ] || 0
                        }
                        stop={(time) =>
                          updateTime(wi.target.id, (time / 3600).toFixed(4))
                        }
                        reset={(time) =>
                          updateTime(wi.target.id, (time / 3600).toFixed(4))
                        }
                      />
                    </li>
                  </ul>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Tasks;
