import axios from "axios";

export const API_VERSION = "?api-version=6.1-preview.1";
export const WORK_ITEM_FIELD = "Microsoft.VSTS.Scheduling.CompletedWork";
export const LOCAL_STORAGE_KEY = "KIRANA_MSFT_PAT";
export const LOCAL_ORG_STORAGE_KEY = "KIRANA_MSFT_ORG";

export const api = axios.create();

export function getPAT() {
  let pat = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!pat) {
    pat = prompt(
      "Enter your organization PAT (remember to assing peremissions for WorkItem editing):"
    );
    localStorage.setItem(LOCAL_STORAGE_KEY, pat);
  }
  return pat;
}

export function getOrg() {
  let org = localStorage.getItem(LOCAL_ORG_STORAGE_KEY);
  if (!org) {
    org = prompt("Enter your organization name:");
    localStorage.setItem(LOCAL_ORG_STORAGE_KEY, org);
  }
  return org;
}

function getApiEndpoint() {
  const org = getOrg();
  return `https://dev.azure.com/${org}`;
}

export function setUpAuth() {
  const pat = getPAT();
  api.interceptors.request.use((req) => {
    req.auth = {
      username: "",
      password: pat,
    };
    return req;
  });
}

export function getAllProjects() {
  return api.get(`${getApiEndpoint()}/_apis/projects${API_VERSION}`);
}

export function getAllIterations(projectName) {
  return api.get(
    `${getApiEndpoint()}/${projectName}/${projectName} Team/_apis/work/teamsettings/iterations${API_VERSION}`
  );
}

export function getIterationWorkItems(projectName, iterationId) {
  return api.get(
    `${getApiEndpoint()}/${projectName}/${projectName} Team/_apis/work/teamsettings/iterations/${iterationId}/workitems${API_VERSION}`
  );
}

export function updateCompletedTime(workItemId, time) {
  return api.patch(
    `${getApiEndpoint()}/_apis/wit/workitems/${workItemId}?api-version=6.1-preview.3`,
    [
      {
        op: "add",
        path: "/fields/Microsoft.VSTS.Scheduling.CompletedWork",
        value: time,
      },
    ],
    {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    }
  );
}

export function getWorkItemsBatch(ids) {
  if (ids.length === 0) {
    return;
  }
  const fields = ["System.Title", WORK_ITEM_FIELD];
  return api.post(
    `${getApiEndpoint()}/_apis/wit/workitemsbatch${API_VERSION}`,
    {
      ids: ids,
      fields: fields,
    }
  );
}
