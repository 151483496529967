// import logo from './logo.svg';
import * as React from "react";
import "./Home.css";
import { useEffect, useState } from "react";
import { getAllProjects, setUpAuth } from "../services/base-service";
import { Depths } from "@fluentui/react";
import { Link } from "react-router-dom";

function Home(props) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setUpAuth();
    getAllProjects().then((response) => {
      setProjects(response.data.value);
    });
  }, []);

  const getImageSource = (name) => {
    return `https://dev.azure.com/kirana-labs/_apis/GraphProfile/MemberAvatars/900147e6-bfcd-4c79-8f07-7945e6d34ee9?overrideDisplayName=${name}&size=2`;
  };

  return (
    <div className="App">
      <h1>Selecciona un proyecto para continuar:</h1>
      <div className="project-grid">
        {projects.length > 0 &&
          projects.map((val) => (
            <Link
              key={val.id}
              className="project-card"
              style={{ boxShadow: Depths.depth4 }}
              to={`/tasks/${val.name}`}
            >
              <div className="project-profile">
                <img alt={val.name} src={getImageSource(val.name)} />
              </div>
              <div className="info">
                <h2>{val.name}</h2>
                <p>{val.description}</p>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

export default Home;
