import * as React from "react";
import { useState, useEffect } from "react";
import { PlayIcon, PauseIcon } from "@fluentui/react-icons-mdl2";
import "./Stopwatch.css";

function Stopwatch(props) {
  // let [timerOn, setTimerOn] = useState(false);
  let [timerTime, setTimerTime] = useState(props.startTime * 3600 || 0);
  let [timer, setTimer] = useState(null);

  const startTimer = () => {
    // setTimerOn(true);
    setTimerTime(timerTime);

    setTimer(
      setInterval(() => {
        setTimerTime(timerTime++);
      }, 1000)
    );

    props.start && props.start();
  };

  useEffect(() => {
    setTimerTime(props.startTime * 3600 || 0);
  }, [props]);

  const stopTimer = () => {
    // setTimerOn(false);
    clearInterval(timer);

    props.stop && props.stop(timerTime);
  };

  //   const resetTimer = () => {
  //     // setTimerOn(false);
  //     clearInterval(timer);
  //     props.reset && props.reset(timerTime);
  //
  //     setTimerTime(0);
  //   };

  //   <StopIcon
  //   style={{
  //     height: 20,
  //     width: 20,
  //     color: "rgb(241, 112, 123)",
  //   }}
  //   onClick={() => resetTimer()}
  // />

  let seconds = ("0" + (Math.floor(timerTime) % 60)).slice(-2);
  let minutes = ("0" + (Math.floor(timerTime / 60) % 60)).slice(-2);
  let hours = ("0" + (Math.floor(timerTime / 3600) % 60)).slice(-2);
  return (
    <div className="stopwatch">
      <p>
        {hours}:{minutes}:{seconds}
      </p>
      <PauseIcon
        style={{
          height: 20,
          width: 20,
          color: "rgb(152, 198, 255)",
        }}
        onClick={() => stopTimer()}
      />
      <PlayIcon
        style={{
          height: 20,
          width: 20,
          color: "rgb(146, 195, 83)",
        }}
        onClick={() => startTimer()}
      />
    </div>
  );
}

export default Stopwatch;
